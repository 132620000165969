import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faHome } from "@fortawesome/free-solid-svg-icons";

type Props = {
  name?: string,
  icon?: IconDefinition,
  count?: number
}
type State = {}

export class NavbarItem extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const { name, icon, count } = this.props;

    return (
      <a
        className="w-full text-gray-800 dark:text-white flex items-center pl-6 p-2 my-2 transition-colors duration-200 justify-start border-l-4 border-purple-500"
        href="#">
        <span className="text-left">
          <FontAwesomeIcon icon={icon || faHome} />
        </span>
        <span className="mx-2 text-sm font-normal">
          {name}
          {count && <span className="p-1 ml-4 rounded-lg w-4 h-2 bg-gray-200 text-gray-400 text-xs">{count}</span>}
        </span>
      </a>
    );
  };
}
