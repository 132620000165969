import React from "react";
import backend from "api/backend";
import { AxiosResponse } from "axios";
import { BacklogResponse } from "types/chart";

type Props = {}
type State = {
  freeMemory: number,
  queueBacklog: number,
  totalMemory: number,
  maxMemory: number
}

export class Backlog extends React.Component<Props, State> {
  state: State = {
    freeMemory: 0,
    queueBacklog: 0,
    totalMemory: 0,
    maxMemory: 0
  };
  timeoutId: any;
  errors: number = 0;

  getBacklogData = () => {
    if (this.errors >= 3) {
      return;
    }

    backend.get("/api/backlog", {
      params: {}
    }).then((response: AxiosResponse<BacklogResponse>) => {
      this.setState({
        freeMemory: response.data.freeMemory,
        queueBacklog: response.data.queueBacklog,
        totalMemory: response.data.totalMemory,
        maxMemory: response.data.maxMemory
      });
    }).catch((err) => {
      this.errors += 1;
    });
  };

  componentDidMount = () => {
    this.errors = 0;
    this.getBacklogData();
    clearTimeout(this.timeoutId);
    this.timeoutId = setInterval(this.getBacklogData, 60 * 1000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutId);
  };

  render = () => {
    const {} = this.props;
    const { freeMemory, queueBacklog, totalMemory, maxMemory } = this.state;

    return (
      <div>
        <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200">
          Backlog
        </p>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-black dark:text-white font-bold">
            {queueBacklog}
          </p>
        </div>
        <div className="dark:text-white">
          <div
            className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Free memory
            </p>
            <div className="flex items-end text-xs">
              {freeMemory} MB
            </div>
          </div>
          <div
            className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Total memory
            </p>
            <div className="flex items-end text-xs">
              {totalMemory} MB
            </div>
          </div>
          <div className="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
            <p>
              Max memory
            </p>
            <div className="flex items-end text-xs">
              {maxMemory} MB
            </div>
          </div>
        </div>
      </div>
    );
  };
}
