import axios from "axios";
import { Constants } from "util/constants";

export default axios.create({
  baseURL: Constants.API_BASE_URL + "/",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
});
