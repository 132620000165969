import React from "react";
import { ChartResponse, MarketItem, PriceQuantitySignalItem, PriceQuantitySignalResponse } from "types/chart";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import backend from "api/backend";
import { AxiosResponse } from "axios";

type Props = {
  marketDetails: MarketItem[]
}
type State = {
  signalList: PriceQuantitySignalItem[]
}

export class Signal extends React.Component<Props, State> {
  state: State = {
    signalList: []
  };
  timeoutId: any;
  errors: number = 0;

  getPriceQuantitySignalData = () => {
    if (this.errors >= 3) {
      return;
    }

    backend.get("/table/price-quantity-signal", {
      params: {}
    }).then((response: AxiosResponse<PriceQuantitySignalResponse>) => {
      this.setState({ signalList: response.data.signalList });
    }).catch((err) => {
      this.errors += 1;
    });
  };

  componentDidMount = () => {
    this.errors = 0;
    this.getPriceQuantitySignalData();
    clearTimeout(this.timeoutId);
    this.timeoutId = setInterval(this.getPriceQuantitySignalData, 5 * 60 * 1000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutId);
  };

  render = () => {
    const { signalList } = this.state;

    const columns: GridColDef[] = [
      {
        field: "pair",
        headerName: "Pair",
        width: 200,
        resizable: false,
        sortable: true,
        headerAlign: "left",
        align: "left",
        disableColumnMenu: true
      },
      {
        field: "price_signal",
        headerName: "Price Signal",
        type: "number",
        width: 170,
        resizable: false,
        sortable: true,
        headerAlign: "left",
        align: "left",
        disableColumnMenu: true
      },
      {
        field: "quantity_signal",
        headerName: "Quantity Signal",
        type: "number",
        width: 170,
        resizable: false,
        sortable: true,
        headerAlign: "left",
        align: "left",
        disableColumnMenu: true
      },
      {
        field: "signal",
        headerName: "Signal",
        type: "number",
        width: 170,
        resizable: false,
        sortable: true,
        headerAlign: "left",
        align: "left",
        disableColumnMenu: true
      }
    ];

    signalList.map(item => {
      item.id = item.pair;
      return item;
    });

    return (
      <div style={{ height: 1082, width: "100%" }}>
        <DataGrid
          rows={signalList}
          columns={columns}
          style={{ color: "white" }}
          onSelectionModelChange={(model, details) => {
            if (model && model.length != 0) {
              const customEvent = new CustomEvent("Signal.on-click-pair", { detail: { pair: String(model[0]) } });
              document.dispatchEvent(customEvent);
            }
          }}
        />
      </div>
    );
  };
}
