import React from "react";
import backend from "api/backend";
import { AxiosResponse } from "axios";
import {
  ChartResponse,
  ChartResponseItem,
  GroupData, MarketItem,
  SeriesDataItem
} from "types/chart";
import { CanvasJSChart } from "lib/canvasjs.stock.react";

type SeriesData = {
  seriesData: SeriesDataItem[],
  minY: number,
  maxY: number
}

type Props = {
  marketDetails: MarketItem[]
}
type State = {
  change: ChartResponseItem[],
  score: ChartResponseItem[],
  score1: ChartResponseItem[],
  score2: ChartResponseItem[],
  selectedPair: string
}

export class ChangeChart extends React.Component<Props, State> {
  state: State = {
    change: [],
    score: [],
    score1: [],
    score2: [],
    selectedPair: "none"
  };
  timeoutId: any;
  errors: number = 0;

  getTopGainersData = () => {
    if (this.errors >= 3) {
      return;
    }

    backend.get("/chart/top-gainers", {
      params: {}
    }).then((response: AxiosResponse<ChartResponse>) => {
      this.setState({
        change: response.data.change,
        score: response.data.score,
        score1: response.data.score1,
        score2: response.data.score2
      });
    }).catch((err) => {
      this.errors += 1;
    });
  };

  signalOnClickPair = (event: Event) => {
    // @ts-ignore
    this.setState({ selectedPair: event.detail.pair });
  };

  componentDidMount = () => {
    this.errors = 0;
    this.getTopGainersData();
    clearTimeout(this.timeoutId);
    this.timeoutId = setInterval(this.getTopGainersData, 5 * 60 * 1000);
    document.addEventListener("Signal.on-click-pair", this.signalOnClickPair);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutId);
    document.removeEventListener("Signal.on-click-pair", this.signalOnClickPair);
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
    const { marketDetails } = this.props;

    if (marketDetails.length > 0 && prevProps.marketDetails !== marketDetails) {
      this.getTopGainersData();
    }
  };

  getSeriesData = (chartData: ChartResponseItem[]): SeriesData => {
    const { marketDetails } = this.props;
    const { selectedPair } = this.state;

    let seriesColorMap: Map<string, string> = new Map();
    marketDetails.forEach(item => {
      seriesColorMap.set(item.pair, item.color);
    });

    let maxY: number = -1;
    let minY: number = 1000;
    let groupedData: GroupData = {};
    chartData.forEach(item => {
      if (!groupedData[item.series]) {
        groupedData[item.series] = [];
      }
      groupedData[item.series].push({
        x: new Date(item.x),
        y: item.y
      });
      maxY = item.y > maxY ? item.y : maxY;
      minY = item.y < minY ? item.y : minY;
    });

    let seriesData: SeriesDataItem[] = [];
    Object.keys(groupedData).forEach(pair => {
      let lineThickness: number;
      if (selectedPair === "none") {
        lineThickness = 2;
      } else {
        if (selectedPair === pair) {
          lineThickness = 4;
        } else {
          lineThickness = 0.5;
        }
      }

      seriesData.push({
        name: pair,
        type: "line",
        lineThickness: lineThickness,
        lineColor: seriesColorMap.get(pair) || "",
        showInLegend: false,
        dataPoints: groupedData[pair],
        markerType: "circle",
        markerSize: 2.5,
        markerColor: seriesColorMap.get(pair) || "",
        click: (e) => {
          this.setState({ selectedPair: e.dataSeries.name });
          const customEvent = new CustomEvent("Change.on-click-pair", { detail: { pair: e.dataSeries.name } });
          document.dispatchEvent(customEvent);
        }
      });
    });

    return {
      seriesData: seriesData,
      maxY: maxY,
      minY: minY
    };
  };

  getChartOptions = (title: string, seriesData: SeriesData, height: number) => {
    return {
      theme: "dark2",
      height: height,
      title: {
        text: title,
        fontSize: 16
      },
      animationEnabled: false,
      axisX: {
        valueFormatString: "hh:mm TT",
        labelFontSize: 11
      },
      axisY: {
        labelFontSize: 11,
        gridThickness: 0.5,
        gridDashType: "dash",
        minimum: seriesData.minY,
        maximum: seriesData.maxY
      },
      toolTip: {
        content: "{name}: {y}% @ {x}"
      },
      data: seriesData.seriesData
    };
  };

  render = () => {
    const { marketDetails } = this.props;
    const { change, score, score1, score2, selectedPair } = this.state;

    let dropDownOptions = marketDetails.map(item => {
      return (
        <option key={item.pair} value={item.pair}>
          {item.coinDcxName} - {item.pair}
        </option>
      );
    });
    dropDownOptions = [<option key={"none"} value={"none"}>None</option>, ...dropDownOptions];

    let changeSeriesData = this.getSeriesData(change);
    let scoreSeriesData = this.getSeriesData(score);
    let score1SeriesData = this.getSeriesData(score1);
    let score2SeriesData = this.getSeriesData(score2);

    let changeChartOptions = this.getChartOptions("% Change", changeSeriesData, 750);
    let scoreChartOptions = this.getChartOptions("Score", scoreSeriesData, 250);
    let score1ChartOptions = this.getChartOptions("Score 1", score1SeriesData, 250);
    let score2ChartOptions = this.getChartOptions("Score 2", score2SeriesData, 250);

    return (
      <div>
        <div className="grid grid-cols-2 grid-rows-1" style={{ height: "50px" }}>
          <p className="item-start text-lg w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200">
            Top Gainers
          </p>
          <div className="flex items-end justify-self-end">
            <div className="flex items-center space-x-4">
              <select
                className="block w-64 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                value={selectedPair}
                onChange={(event) => {
                  this.setState({ selectedPair: event.target.value });
                  const customEvent = new CustomEvent("Change.on-click-pair", { detail: { pair: event.target.value } });
                  document.dispatchEvent(customEvent);
                }}>
                {dropDownOptions}
              </select>
            </div>
          </div>
        </div>
        <div className={"grid grid-cols-1 grid-rows-4 gap-1 my-1"}>
          <div className={"col-span-1 row-span-3"}>
            <CanvasJSChart options={changeChartOptions} />
          </div>
          <div className={"col-span-1 row-span-1"}>
            <CanvasJSChart options={scoreChartOptions} />
          </div>
          {/*<div className={"grid grid-cols-1 grid-rows-1 gap-1 my-1"}>*/}
          {/*  <CanvasJSChart options={scoreChartOptions} />*/}
          {/*  <CanvasJSChart options={score1ChartOptions} />*/}
          {/*  <CanvasJSChart options={score2ChartOptions} />*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };
}
