import React from "react";
import { Navbar } from "components/elements/Navbar";
import { Header } from "components/elements/Header";
import { Summary } from "components/elements/Summary";
import { TradeHistory } from "components/card/TradeHistory";
import { Backlog } from "components/card/Backlog";
import { TopGainers } from "components/card/TopGainers";
import { MarketItem, MarketResponse } from "types/chart";
import backend from "api/backend";
import { AxiosResponse } from "axios";
import { Signal } from "components/table/Signal";

type Props = {}
type State = {
  marketDetails: MarketItem[]
}

export class Main extends React.Component<Props, State> {
  state: State = {
    marketDetails: []
  };

  seriesColorSet: string[] = [
    "#6D78AD",
    "#51CDA0",
    "#DF7970",
    "#4C9CA0",
    "#AE7D99",
    "#C9D45C",
    "#5592AD",
    "#DF874D",
    "#52BCA8",
    "#8E7AA3",
    "#E3CB64",
    "#C77B85",
    "#C39762",
    "#8DD17E",
    "#B57952",
    "#FCC26C"
  ];

  componentDidMount = () => {
    if (!document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.add("dark");
    }
    this.getMarketData();
  };

  getMarketData = () => {
    backend.get("/api/market", {
      params: {}
    }).then((response: AxiosResponse<MarketResponse>) => {
      let marketDetails: MarketItem[] = response.data.marketList.map((item, index) => {
        return {
          ...item,
          color: this.seriesColorSet[index % this.seriesColorSet.length]
        };
      });
      this.setState({ marketDetails: marketDetails });
    });
  };

  render = () => {
    const { marketDetails } = this.state;

    return (
      <main className="bg-gray-100 dark:bg-gray-800 h-screen overflow-hidden relative">
        <div className="flex items-start justify-between">
          <Navbar title={"CT"} />
          <div className="flex flex-col w-full md:space-y-4">
            <Header
              name={"Sai Kiran"}
              profileImage={"https://secure.gravatar.com/avatar/542d6d71d914ee58c3a90218de078666"}
            />
            <div className="overflow-auto h-screen pb-24 px-4 md:px-6">
              <h1 className="text-4xl font-semibold text-gray-800 dark:text-white">
                Dashboard
              </h1>
              <h2 className="text-md text-gray-400">
                Here&#x27;s what&#x27;s happening with your portfolio.
              </h2>
              <Summary />

              <div className="grid grid-cols-3 grid-rows-12 gap-4 my-4">
                <div className="col-span-3 row-span-4 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <TradeHistory marketDetails={marketDetails} />
                  </div>
                </div>

                <div className="col-span-1 row-span-1 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <Backlog />
                  </div>
                </div>
                <div className="col-span-1 row-span-1 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200">
                      New features
                    </p>
                    <div className="flex items-end space-x-2 my-6">
                      <p className="text-5xl text-black dark:text-white font-bold">
                        12
                      </p>
                      <span className="text-red-500 text-xl font-bold flex items-center">
                        <svg width="20" fill="currentColor" height="20" className="h-3 rotate-180 transform"
                             viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                          </path>
                        </svg>
                        2%
                      </span>
                    </div>
                    <div className="dark:text-white">
                      <div
                        className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
                        <p>
                          Down
                        </p>
                        <div className="flex items-end text-xs">
                          34
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-red-500 rotate-180 transform"
                                 viewBox="0 0 1792 1792"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            22%
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
                        <p>
                          Up
                        </p>
                        <div className="flex items-end text-xs">
                          13
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-green-500"
                                 viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            12%
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
                        <p>
                          No developed
                        </p>
                        <div className="flex items-end text-xs">
                          45
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-red-500 rotate-180 transform"
                                 viewBox="0 0 1792 1792"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            41%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 row-span-1 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <p className="text-sm w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200">
                      Sign in
                    </p>
                    <div className="flex items-end space-x-2 my-6">
                      <p className="text-5xl text-black dark:text-white font-bold">
                        16
                      </p>
                      <span className="text-red-500 text-xl font-bold flex items-center">
                        <svg width="20" fill="currentColor" height="20" className="h-3 transform rotate-180"
                             viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                          </path>
                        </svg>
                        14%
                      </span>
                    </div>
                    <div className="dark:text-white">
                      <div
                        className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
                        <p>
                          Amercia
                        </p>
                        <div className="flex items-end text-xs">
                          43
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-red-500 rotate-180 transform"
                                 viewBox="0 0 1792 1792"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            12%
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
                        <p>
                          Europe
                        </p>
                        <div className="flex items-end text-xs">
                          133
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-green-500"
                                 viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            19%
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
                        <p>
                          Asia
                        </p>
                        <div className="flex items-end text-xs">
                          23
                          <span className="flex items-center">
                            <svg width="20" fill="currentColor" height="20"
                                 className="h-3 text-red-500 rotate-180 transform"
                                 viewBox="0 0 1792 1792"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                              </path>
                            </svg>
                            4%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 row-span-4 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <TopGainers marketDetails={marketDetails} />
                  </div>
                </div>

                <div className="col-span-1 row-span-4 w-full">
                  <div className="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                    <Signal marketDetails={marketDetails} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  };
}
