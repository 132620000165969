import React, { ChangeEvent } from "react";
import { LineStock } from "components/chart/LineStock";
import { MarketItem } from "types/chart";

type Props = {
  marketDetails: MarketItem[]
}
type State = {
  selectedPair: string,
  selectedDateRange: string,
  selectedGroupMinutes: number
}

export class TradeHistory extends React.Component<Props, State> {
  state: State = {
    selectedPair: "B-1INCH_BTC",
    selectedDateRange: "3days",
    selectedGroupMinutes: 1
  };

  signalOnClickPair = (event: Event) => {
    // @ts-ignore
    this.setState({ selectedPair: event.detail.pair });
  };

  componentDidMount = () => {
    document.addEventListener("Signal.on-click-pair", this.signalOnClickPair);
    document.addEventListener("Change.on-click-pair", this.signalOnClickPair);
  };

  componentWillUnmount = () => {
    document.removeEventListener("Signal.on-click-pair", this.signalOnClickPair);
    document.removeEventListener("Change.on-click-pair", this.signalOnClickPair);
  };

  selectedDateRangeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedDateRange: event.target.value });
  };

  render = () => {
    const { marketDetails } = this.props;
    const { selectedPair, selectedDateRange, selectedGroupMinutes } = this.state;

    const dropDownOptions = marketDetails.map(item => {
      return (
        <option key={item.pair} value={item.pair}>
          {item.coinDcxName} - {item.pair}
        </option>
      );
    });

    return (
      <div>
        <div className="grid grid-cols-2 grid-rows-1">
          <p
            className="item-start text-lg w-max text-gray-700 dark:text-white font-semibold border-b border-gray-200">
            Trade History
          </p>
          <div className="flex items-end justify-self-end">
            <div className="flex items-center space-x-4">
              <select
                className="block w-64 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                value={selectedPair}
                onChange={(event) => {
                  this.setState({ selectedPair: event.target.value });
                }}>
                {dropDownOptions}
              </select>
              <select
                className="block w-36 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                value={selectedGroupMinutes}
                onChange={(event) => {
                  this.setState({ selectedGroupMinutes: parseInt(event.target.value) });
                }}>
                <option value={1}>
                  1 min
                </option>
                <option value={5}>
                  5 min
                </option>
                <option value={60}>
                  60 min
                </option>
                <option value={24 * 60}>
                  24 hr
                </option>
              </select>
              <select
                className="block w-36 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                value={selectedDateRange}
                onChange={this.selectedDateRangeChange}>
                <option value={"1day"}>
                  24 Hrs
                </option>
                <option value={"3days"}>
                  3 days
                </option>
                <option value={"5days"}>
                  5 days
                </option>
              </select>
            </div>
          </div>
        </div>
        <LineStock pair={selectedPair} rangeString={selectedDateRange} groupMinutes={selectedGroupMinutes} />
      </div>
    );
  };
}
