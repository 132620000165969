import React from "react";
import backend from "api/backend";
import moment from "moment";
import { AxiosResponse } from "axios";
import { DateRange, OrderHistoryItem, TradeHistoryItem, TradeHistoryResponse } from "types/chart";
import { CanvasJSStockChart } from "lib/canvasjs.stock.react";
import { Colors } from "types/colors";

type Props = {
  pair: string,
  rangeString: string,
  groupMinutes: number
}
type State = {
  tradeHistory: TradeHistoryItem[],
  orderHistory: OrderHistoryItem[]
}

export class LineStock extends React.Component<Props, State> {
  state: State = {
    tradeHistory: [],
    orderHistory: []
  };
  timeoutId: any;
  errors: number = 0;

  componentDidMount = () => {
    this.errors = 0;
    this.fetchTradeHistory();
    clearTimeout(this.timeoutId);
    this.timeoutId = setInterval(this.fetchTradeHistory, 5 * 60 * 1000);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutId);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { pair, rangeString, groupMinutes } = this.props;

    if (prevProps.pair !== pair || prevProps.rangeString !== rangeString || prevProps.groupMinutes !== groupMinutes) {
      this.fetchTradeHistory();
    }
  }

  fetchTradeHistory = () => {
    if (this.errors >= 3) {
      return;
    }

    const { pair, rangeString, groupMinutes } = this.props;
    if (!pair) {
      return;
    }

    let dateRange = this.getDateRange(rangeString);

    backend.get("/api/trade-history", {
      params: {
        pair: pair,
        from: dateRange.from.toISOString(true),
        to: dateRange.to.toISOString(true),
        groupMinutes: groupMinutes
      }
    }).then((response: AxiosResponse<TradeHistoryResponse>) => {
      this.setState({
        tradeHistory: response.data.tradeHistory,
        orderHistory: response.data.orderHistory
      });
    }).catch((err) => {
      this.errors += 1;
    });
  };

  getDateRange = (rangeString: string): DateRange => {
    let dateRange: DateRange;
    if (rangeString === "1day") {
      dateRange = {
        from: moment().subtract(1, "day"),
        to: moment()
      };
    } else if (rangeString === "3days") {
      dateRange = {
        from: moment().subtract(3, "day"),
        to: moment()
      };
    } else if (rangeString === "5days") {
      dateRange = {
        from: moment().subtract(5, "day"),
        to: moment()
      };
    } else {
      dateRange = {
        from: moment().subtract(3, "day"),
        to: moment()
      };
    }
    return dateRange;
  };

  render = () => {
    const { pair } = this.props;
    const { tradeHistory, orderHistory } = this.state;

    tradeHistory.map(item => {
      if (!item.price) {
        item.price = {
          open: null,
          max: null,
          min: null,
          close: null,
          openAt: null,
          closeAt: null
        };
      }
      if (!item.priceAverage) {
        item.priceAverage = {
          5: null,
          60: null,
          1440: null
        };
      }
      if (!item.quantityAverage) {
        item.quantityAverage = {
          5: null,
          60: null,
          1440: null
        };
      }
      return item;
    });

    let quantityMax: number = -1;
    tradeHistory.forEach((data, index) => {
      if (data.quantity > quantityMax) {
        quantityMax = data.quantity;
      }
    });

    let signalMax: number = -1;
    let signalMin: number = 10;
    const signal = tradeHistory.map(item => {
      let quantitySignal: number = 0;
      if (item.quantityAverage[5] && item.quantityAverage[1440]) {
        quantitySignal = item.quantityAverage[5] / item.quantityAverage[1440];
      }
      let priceSignal: number = 0;
      if (item.priceAverage[5] && item.priceAverage[1440]) {
        priceSignal = item.priceAverage[5] / item.priceAverage[1440];
      }

      let signal: number = quantitySignal * priceSignal;
      if (Math.max(quantitySignal, priceSignal, signal) > signalMax) {
        signalMax = Math.max(quantitySignal, priceSignal, signal);
      }
      if (Math.min(quantitySignal, priceSignal, signal) < signalMin) {
        signalMin = Math.min(quantitySignal, priceSignal, signal);
      }
      return { x: new Date(item.tradedAt), y: signal };
    });
    const priceSignal = tradeHistory.map(item => {
      let priceSignal: number = 0;
      if (item.priceAverage[5] && item.priceAverage[1440]) {
        priceSignal = item.priceAverage[5] / item.priceAverage[1440];
      }
      return { x: new Date(item.tradedAt), y: priceSignal };
    });
    const quantitySignal = tradeHistory.map(item => {
      let quantitySignal: number = 0;
      if (item.quantityAverage[5] && item.quantityAverage[1440]) {
        quantitySignal = item.quantityAverage[5] / item.quantityAverage[1440];
      }
      return { x: new Date(item.tradedAt), y: quantitySignal };
    });

    const options = {
      theme: "dark2",
      rangeSelector: {
        enabled: false
      },
      charts: [
        {
          axisX: {
            lineThickness: 5,
            tickLength: 0,
            labelFormatter: (e: any) => {
              return "";
            },
            crosshair: {
              enabled: true,
              snapToDataPoint: true,
              labelFormatter: (e: any) => {
                return moment(new Date(e.value)).format("Do MMM, hh:mm a");
              }
            }
          },
          axisY: [
            {
              title: pair,
              tickLength: 0,
              labelFontSize: 12,
              titleFontSize: 16,
              gridThickness: 0.5,
              gridDashType: "dash"
            },
            {
              gridThickness: 0,
              tickLength: 0,
              lineThickness: 0,
              labelFormatter: () => {
                return "";
              },
              minimum: 0,
              maximum: quantityMax * 3
            }
          ],
          toolTip: {
            shared: true
          },
          data: [
            {
              name: "MA 5",
              type: "line",
              color: Colors.SET2[2],
              markerType: "none",
              lineDashType: "dot",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.priceAverage[5] };
              })
            },
            {
              name: "MA 60",
              type: "line",
              color: Colors.SET2[3],
              markerType: "none",
              lineDashType: "shortDashDotDot",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.priceAverage[60] };
              })
            },
            {
              name: "MA 24hr",
              type: "line",
              color: Colors.SET2[4],
              markerType: "none",
              lineDashType: "dash",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.priceAverage[1440] };
              })
            },
            {
              name: "Price",
              type: "candlestick",
              risingColor: "rgba(141,209,126,0.7)",
              fallingColor: "rgba(192,80,78,0.7)",
              markerType: "none",
              dataPoints: tradeHistory.map(item => {
                return {
                  x: new Date(item.tradedAt),
                  y: [item.price.open, item.price.max, item.price.min, item.price.close],
                  color: (
                    item.price.open && item.price.close && (item.price.open <= item.price.close) ?
                      "#8DD17E" :
                      "#C0504E"
                  )
                };
              })
            },
            {
              name: "Buy Signal",
              type: "scatter",
              markerType: "triangle",
              markerSize: 12,
              dataPoints: orderHistory.map(item => {
                let color: string = item.executed ?
                  "#00FF00" :
                  "rgba(0,255,0,0.5)";
                return {
                  x: new Date(item.updatedAt),
                  y: item.pairPrice,
                  color: color
                };
              })
            },
            {
              axisYIndex: 1,
              name: "MA 5",
              type: "area",
              color: Colors.SET2[7],
              markerType: "none",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.quantityAverage[5] };
              })
            },
            {
              axisYIndex: 1,
              name: "MA 60",
              type: "area",
              color: Colors.SET2[8],
              markerType: "none",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.quantityAverage[60] };
              })
            },
            {
              axisYIndex: 1,
              name: "MA 24hr",
              type: "area",
              color: Colors.SET2[9],
              markerType: "none",
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.quantityAverage[1440] };
              })
            },
            {
              axisYIndex: 1,
              name: "Quantity",
              type: "column",
              color: Colors.SET2[5],
              fillOpacity: 0.7,
              dataPoints: tradeHistory.map(item => {
                return { x: new Date(item.tradedAt), y: item.quantity };
              })
            }
          ]
        },
        {
          height: 100,
          axisX: {
            lineThickness: 5,
            tickLength: 0,
            labelFormatter: (e: any) => {
              return "";
            },
            crosshair: {
              enabled: true,
              snapToDataPoint: true,
              labelFormatter: (e: any) => {
                return moment(new Date(e.value)).format("Do MMM, hh:mm a");
              }
            }
          },
          axisY: {
            title: "Signal",
            tickLength: 0,
            labelFontSize: 12,
            titleFontSize: 16,
            gridThickness: 0.5,
            gridDashType: "dash",
            maximum: signalMax,
            minimum: signalMin
          },
          toolTip: {
            shared: true
          },
          data: [
            {
              name: "Signal",
              type: "line",
              color: Colors.SET2[10],
              markerType: "none",
              dataPoints: signal
            },
            {
              name: "Price Signal",
              type: "line",
              color: Colors.SET2[11],
              markerType: "none",
              dataPoints: priceSignal
            },
            {
              name: "Quantity Signal",
              type: "line",
              color: Colors.SET2[12],
              markerType: "none",
              dataPoints: quantitySignal
            }
          ]
        }
      ],
      navigator: {
        height: 100,
        data: [
          {
            type: "area",
            color: Colors.SET2[13],
            dataPoints: tradeHistory.map(item => {
              return { x: new Date(item.tradedAt), y: item.price.open };
            })
          }
        ],
        slider: {
          minimum: new Date(moment().subtract(24, "hours").valueOf()),
          maximum: new Date(moment().valueOf())
        }
      }
    };

    const containerProps = {
      width: "100%",
      height: "1000px",
      margin: "auto"
    };
    return (
      <div className={"grid grid-cols-1 grid-rows-1"}>
        <CanvasJSStockChart containerProps={containerProps} options={options} />
      </div>
    );
  };
}
