import React from "react";
import { ChangeChart } from "components/chart/Change";
import { MarketItem } from "types/chart";

type Props = {
  marketDetails: MarketItem[]
}
type State = {}

export class TopGainers extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    const { marketDetails } = this.props;

    return (
      <ChangeChart marketDetails={marketDetails} />
    );
  };
}
