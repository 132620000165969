import React from "react";
import { NavbarItem } from "components/elements/NavbarItem";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

type Props = {
  title?: string
}
type State = {}

export class Navbar extends React.Component<Props, State> {
  state: State = {};

  componentDidMount = () => {
    const { title } = this.props;
    document.title = title || "Title";
  };

  render = () => {
    const { title } = this.props;

    return (
      <div className="h-screen hidden lg:block shadow-lg relative w-20">
        <div className="bg-white h-full dark:bg-gray-700">
          <div className="flex items-center justify-start pt-6 ml-8">
            <p className="font-bold dark:text-white text-xl">
              {title || "Title"}
            </p>
          </div>
          <nav className="mt-6">
            <NavbarItem name={""} icon={faChartLine} />
          </nav>
        </div>
      </div>
    );
  };
}
