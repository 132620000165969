import React from "react";
import { Main } from "components/elements/Main";

type Props = {}
type State = {}

export class App extends React.Component<Props, State> {
  state: State = {};

  render = () => {
    return (
      <div>
        <Main />
      </div>
    );
  };
}
