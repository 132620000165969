export class Colors {
  static SET1 = [
    "#4F81BC",
    "#C0504E",
    "#9BBB58",
    "#23BFAA",
    "#8064A1",
    "#4AACC5",
    "#F79647",
    "#7F6084",
    "#77A033",
    "#33558B",
    "#E59566"
  ];

  static SET2 = [
    "#6D78AD",
    "#51CDA0",
    "#DF7970",
    "#4C9CA0",
    "#AE7D99",
    "#C9D45C",
    "#5592AD",
    "#DF874D",
    "#52BCA8",
    "#8E7AA3",
    "#E3CB64",
    "#C77B85",
    "#C39762",
    "#8DD17E",
    "#B57952",
    "#FCC26C"
  ];

  static SET3 = [
    "#8CA1BC",
    "#36845C",
    "#017E82",
    "#8CB9D0",
    "#708C98",
    "#94838D",
    "#F08891",
    "#0366A7",
    "#008276",
    "#EE7757",
    "#E5BA3A",
    "#F2990B",
    "#03557B",
    "#782970"
  ];
}
